import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Checklist from '../components/Checklist'

export default function GapAnalysisTemplate({ pageContext, data }) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const productName = filePathParts[0]
  const documentName = filePathParts[2]
  const productChecklist = [
    'Are there New design features?',
    'Are there new materials used?',
    'Are there new intended purposes?',
    'Are there new medical indications?',
    'Are there a new targert population?',
    'Are there new claims the manufacturer intends to use?',
    'Is there a new type of user?',
    'Does the device contact mucosal membranes or broken skin?',
    'Is this an invasive medical device?',
    'Is there an increase in duration of use or re-application of device?',
    'Does the device incorporate any other medical substances?',
    'Does the device use animal tissue(other than in contact with intact skin?',
    'Are there any issues with other lower risk  medical alternatives?',
    'Are there any issues with other products that provide improved medical benefits?',
    'Are any issues raised when new risk are recognized?',
    'Are there data available to support evaluation through clinical investigation?',
    'Have safety and performance data been evaluated from other alternative devices?',
  ]
  const conclusionChecklist = [
    'Does existing data support claim that the device is in conformity with all essential requirements for Clinical performance?',
    'Does existing data support claim that the device is in conformity with all essential requirements for Clinical safety?',
    'Is clinical evaluation required?',
  ]
  return (
    <Layout title={documentName} filePath={filePath}>
      <h2 className="title is-2">Product: {productName}</h2>
      <Checklist checklistItems={productChecklist} />
      <h2 className="title is-2">Seriousness of Direct/Indirect Risk</h2>
      <table className="table is-striped is-narrow is-hoverable">
        <tr>
          <td>
            <h3 className="title is-3">Harm</h3>
          </td>
          <td>
            <div className="select">
              <select>
                {[1, 2, 3, 4, 5].map((n) => (
                  <option>{n}</option>
                ))}
              </select>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h3 className="title is-3">Probability of Occurrence</h3>
          </td>
          <td>
            <div className="select">
              <select>
                {[0, 1, 2, 3, 4, 5].map((n) => (
                  <option>{n}</option>
                ))}
              </select>
            </div>
          </td>
        </tr>
      </table>
      <p>
        See{' '}
        <Link to={`${productName}/Overview Documents/risk-management-file`}>
          Risk Mangement File
        </Link>{' '}
        Tab B
      </p>
      <h2 className="title is-2">Conclusion</h2>
      <Checklist checklistItems={conclusionChecklist} />
    </Layout>
  )
}

//query all files in the parent directory
export const query = graphql`
  query MarkdownQueryGapAnalysis($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        templateName
      }
    }
  }
`
